
 const Cities =  [
  {
    "types": "Riyadh", 
    "lat": "24.6333", 
    "lng": "46.7167", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ar Riyāḑ", 
    "capital": "primary", 
    "population": "7237000", 
    "population_proper": "6694000"
  }, 
  {
    "types": "Jeddah", 
    "lat": "21.5433", 
    "lng": "39.1728", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Makkah al Mukarramah", 
    "capital": "", 
    "population": "3976000", 
    "population_proper": "3976000"
  }, 
  {
    "types": "Mecca", 
    "lat": "21.4225", 
    "lng": "39.8233", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Makkah al Mukarramah", 
    "capital": "admin", 
    "population": "1675368", 
    "population_proper": "1675368"
  }, 
  {
    "types": "Medina", 
    "lat": "24.4700", 
    "lng": "39.6100", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Al Madīnah al Munawwarah", 
    "capital": "admin", 
    "population": "1180770", 
    "population_proper": "1180770"
  }, 
  {
    "types": "Ad Dammām", 
    "lat": "26.4333", 
    "lng": "50.1000", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ash Sharqīyah", 
    "capital": "admin", 
    "population": "903312", 
    "population_proper": "903312"
  }, 
  {
    "types": "Al Hufūf", 
    "lat": "25.3833", 
    "lng": "49.5833", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ash Sharqīyah", 
    "capital": "", 
    "population": "660788", 
    "population_proper": "660788"
  }, 
  {
    "types": "Ḩafr al Bāţin", 
    "lat": "28.4342", 
    "lng": "45.9636", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ash Sharqīyah", 
    "capital": "", 
    "population": "600000", 
    "population_proper": "300642"
  }, 
  {
    "types": "Al Ḩillah", 
    "lat": "23.4895", 
    "lng": "46.7564", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ar Riyāḑ", 
    "capital": "", 
    "population": "594605", 
    "population_proper": "594605"
  }, 
  {
    "types": "Aţ Ţā if", 
    "lat": "21.2751", 
    "lng": "40.4062", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Makkah al Mukarramah", 
    "capital": "", 
    "population": "579970", 
    "population_proper": "579970"
  }, 
  {
    "types": "Tabūk", 
    "lat": "28.3972", 
    "lng": "36.5789", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Tabūk", 
    "capital": "admin", 
    "population": "547957", 
    "population_proper": "512629"
  }, 
  {
    "types": "Al Qaţīf", 
    "lat": "26.5560", 
    "lng": "49.9960", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ash Sharqīyah", 
    "capital": "", 
    "population": "524182", 
    "population_proper": "524182"
  }, 
  {
    "types": "Buraydah", 
    "lat": "26.3333", 
    "lng": "43.9667", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Al Qaşīm", 
    "capital": "admin", 
    "population": "467410", 
    "population_proper": "467410"
  }, 
  {
    "types": "Al Jubayl", 
    "lat": "27.0000", 
    "lng": "49.6544", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ash Sharqīyah", 
    "capital": "", 
    "population": "392948", 
    "population_proper": "392948"
  }, 
  {
    "types": "Ḩā il", 
    "lat": "27.5167", 
    "lng": "41.6833", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ḩā il", 
    "capital": "admin", 
    "population": "385257", 
    "population_proper": "310897"
  }, 
  {
    "types": "Al Kharj", 
    "lat": "24.1483", 
    "lng": "47.3050", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ar Riyāḑ", 
    "capital": "", 
    "population": "376325", 
    "population_proper": "376325"
  }, 
  {
    "types": "Al Qunfudhah", 
    "lat": "19.1264", 
    "lng": "41.0789", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Makkah al Mukarramah", 
    "capital": "", 
    "population": "300516", 
    "population_proper": "300516"
  }, 
  {
    "types": "Al Mubarraz", 
    "lat": "25.4416", 
    "lng": "49.6642", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ash Sharqīyah", 
    "capital": "", 
    "population": "298562", 
    "population_proper": "298562"
  }, 
  {
    "types": "Yanbu", 
    "lat": "24.0883", 
    "lng": "38.0582", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Al Madīnah al Munawwarah", 
    "capital": "", 
    "population": "250000", 
    "population_proper": "188430"
  }, 
  {
    "types": "Sakākā", 
    "lat": "29.9697", 
    "lng": "40.2000", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Al Jawf", 
    "capital": "admin", 
    "population": "242813", 
    "population_proper": "242813"
  }, 
  {
    "types": "Abhā", 
    "lat": "18.2169", 
    "lng": "42.5053", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": " Asīr", 
    "capital": "admin", 
    "population": "236157", 
    "population_proper": "236157"
  }, 
  {
    "types": "Şabyā", 
    "lat": "17.1489", 
    "lng": "42.6258", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Jāzān", 
    "capital": "", 
    "population": "228375", 
    "population_proper": "228375"
  }, 
  {
    "types": "Al Khubar", 
    "lat": "26.2833", 
    "lng": "50.2000", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ash Sharqīyah", 
    "capital": "", 
    "population": "219679", 
    "population_proper": "219679"
  }, 
  {
    "types": "Qal at Bīshah", 
    "lat": "20.0000", 
    "lng": "42.6000", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": " Asīr", 
    "capital": "", 
    "population": "205346", 
    "population_proper": "205346"
  }, 
  {
    "types": " Unayzah", 
    "lat": "26.0906", 
    "lng": "43.9875", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Al Qaşīm", 
    "capital": "", 
    "population": "163729", 
    "population_proper": "163729"
  }, 
  {
    "types": "Ras Tanura", 
    "lat": "26.6500", 
    "lng": "50.1667", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ash Sharqīyah", 
    "capital": "", 
    "population": "153933", 
    "population_proper": "153933"
  }, 
  {
    "types": "Al Ḩawīyah", 
    "lat": "21.4411", 
    "lng": "40.4975", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Makkah al Mukarramah", 
    "capital": "", 
    "population": "148151", 
    "population_proper": "148151"
  }, 
  {
    "types": "Al Qurayyāt", 
    "lat": "31.3167", 
    "lng": "37.3667", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Al Jawf", 
    "capital": "", 
    "population": "147550", 
    "population_proper": "147550"
  }, 
  {
    "types": "Ar Rass", 
    "lat": "25.8667", 
    "lng": "43.5000", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Al Qaşīm", 
    "capital": "", 
    "population": "133000", 
    "population_proper": "133000"
  }, 
  {
    "types": "Jāzān", 
    "lat": "16.8892", 
    "lng": "42.5611", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Jāzān", 
    "capital": "admin", 
    "population": "127743", 
    "population_proper": "127743"
  }, 
  {
    "types": "Az Zulfī", 
    "lat": "26.2833", 
    "lng": "44.8000", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ar Riyāḑ", 
    "capital": "", 
    "population": "125000", 
    "population_proper": "125000"
  }, 
  {
    "types": "Sayhāt", 
    "lat": "26.4750", 
    "lng": "50.0417", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ash Sharqīyah", 
    "capital": "", 
    "population": "115000", 
    "population_proper": "115000"
  }, 
  {
    "types": "Ḩaraḑ", 
    "lat": "24.1456", 
    "lng": "49.0653", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ash Sharqīyah", 
    "capital": "", 
    "population": "111214", 
    "population_proper": "111214"
  }, 
  {
    "types": "Al Aḩad al Masāriḩah", 
    "lat": "16.7097", 
    "lng": "42.9550", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Jāzān", 
    "capital": "", 
    "population": "110710", 
    "population_proper": "110710"
  }, 
  {
    "types": "Khamīs Mushayţ", 
    "lat": "18.3000", 
    "lng": "42.7333", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": " Asīr", 
    "capital": "", 
    "population": "99213", 
    "population_proper": "99213"
  }, 
  {
    "types": "Ţurayf", 
    "lat": "31.6775", 
    "lng": "38.6531", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Al Ḩudūd ash Shamālīyah", 
    "capital": "", 
    "population": "91713", 
    "population_proper": "91713"
  }, 
  {
    "types": "Sharūrah", 
    "lat": "17.4833", 
    "lng": "47.1167", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Najrān", 
    "capital": "", 
    "population": "85977", 
    "population_proper": "85977"
  }, 
  {
    "types": "Rafḩā", 
    "lat": "29.6386", 
    "lng": "43.5014", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Al Ḩudūd ash Shamālīyah", 
    "capital": "", 
    "population": "80544", 
    "population_proper": "80544"
  }, 
  {
    "types": "Najrān", 
    "lat": "17.4917", 
    "lng": "44.1322", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Najrān", 
    "capital": "admin", 
    "population": "72038", 
    "population_proper": "72038"
  }, 
  {
    "types": "Al Līth", 
    "lat": "20.1500", 
    "lng": "40.2667", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Makkah al Mukarramah", 
    "capital": "", 
    "population": "72000", 
    "population_proper": "72000"
  }, 
  {
    "types": "Ad Darb", 
    "lat": "17.7167", 
    "lng": "42.2500", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Jāzān", 
    "capital": "", 
    "population": "69134", 
    "population_proper": "69134"
  }, 
  {
    "types": "Ra s al Khafjī", 
    "lat": "28.4167", 
    "lng": "48.5000", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ash Sharqīyah", 
    "capital": "", 
    "population": "65000", 
    "population_proper": "65000"
  }, 
  {
    "types": "Badr Ḩunayn", 
    "lat": "23.7800", 
    "lng": "38.7906", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Al Madīnah al Munawwarah", 
    "capital": "", 
    "population": "63468", 
    "population_proper": "63468"
  }, 
  {
    "types": "Khulayş", 
    "lat": "22.1539", 
    "lng": "39.3183", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Makkah al Mukarramah", 
    "capital": "", 
    "population": "63445", 
    "population_proper": "63445"
  }, 
  {
    "types": "An Nimāş", 
    "lat": "19.1200", 
    "lng": "42.1300", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": " Asīr", 
    "capital": "", 
    "population": "53908", 
    "population_proper": "53908"
  }, 
  {
    "types": "Al Majāridah", 
    "lat": "19.1167", 
    "lng": "41.9167", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": " Asīr", 
    "capital": "", 
    "population": "53629", 
    "population_proper": "53629"
  }, 
  {
    "types": "Al Wajh", 
    "lat": "26.2833", 
    "lng": "36.4167", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Tabūk", 
    "capital": "", 
    "population": "50000", 
    "population_proper": "50000"
  }, 
  {
    "types": "Al Midhnab", 
    "lat": "25.8601", 
    "lng": "44.2223", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Al Qaşīm", 
    "capital": "", 
    "population": "44043", 
    "population_proper": "44043"
  }, 
  {
    "types": "Abqaiq", 
    "lat": "25.9350", 
    "lng": "49.6661", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ash Sharqīyah", 
    "capital": "", 
    "population": "44000", 
    "population_proper": "44000"
  }, 
  {
    "types": "Al  Aqīq", 
    "lat": "20.2685", 
    "lng": "41.6493", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Al Bāḩah", 
    "capital": "", 
    "population": "35629", 
    "population_proper": "35629"
  }, 
  {
    "types": "Ḑulay  Rashīd", 
    "lat": "25.5067", 
    "lng": "42.8614", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Al Qaşīm", 
    "capital": "", 
    "population": "35000", 
    "population_proper": "35000"
  }, 
  {
    "types": "Shaqrā ", 
    "lat": "25.2483", 
    "lng": "45.2528", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ar Riyāḑ", 
    "capital": "", 
    "population": "35000", 
    "population_proper": "35000"
  }, 
  {
    "types": "Al Mindak", 
    "lat": "20.1833", 
    "lng": "41.2333", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Al Bāḩah", 
    "capital": "", 
    "population": "33319", 
    "population_proper": "33319"
  }, 
  {
    "types": "Dhahran", 
    "lat": "26.2667", 
    "lng": "50.1500", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ash Sharqīyah", 
    "capital": "", 
    "population": "32067", 
    "population_proper": "32067"
  }, 
  {
    "types": "Al  Aydābī", 
    "lat": "17.2370", 
    "lng": "42.9390", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Jāzān", 
    "capital": "", 
    "population": "31735", 
    "population_proper": "31735"
  }, 
  {
    "types": "Qārā", 
    "lat": "29.8833", 
    "lng": "40.2167", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Al Jawf", 
    "capital": "", 
    "population": "31480", 
    "population_proper": "31480"
  }, 
  {
    "types": "Ash Shinān", 
    "lat": "27.1782", 
    "lng": "42.4430", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Ḩā il", 
    "capital": "", 
    "population": "30441", 
    "population_proper": "30441"
  }, 
  {
    "types": "Arar", 
    "lat": "30.9833", 
    "lng": "41.0167", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Al Ḩudūd ash Shamālīyah", 
    "capital": "admin", 
    "population": "27449", 
    "population_proper": "27449"
  }, 
  {
    "types": "Ḩaql", 
    "lat": "29.2833", 
    "lng": "34.9333", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Al Jawf", 
    "capital": "", 
    "population": "25649", 
    "population_proper": "25649"
  }, 
  {
    "types": "Ḑubā", 
    "lat": "27.3493", 
    "lng": "35.6962", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Tabūk", 
    "capital": "", 
    "population": "25601", 
    "population_proper": "25601"
  }, 
  {
    "types": "Al Bāḩah", 
    "lat": "20.0125", 
    "lng": "41.4653", 
    "country": "Saudi Arabia", 
    "iso2": "SA", 
    "admin_name": "Al Bāḩah", 
    "capital": "admin", 
    "population": "21983", 
    "population_proper": "21983"
  }
]
export const City = Cities.map((item)=>{
  return  item.types
})
