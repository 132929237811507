import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import "./Table.css";
import { SelectComponent } from "../SelectComponent/SelectComponent";

export default function BasicTable({ row, setWeeksInfo, WeeksInfo }) {
  const [rows, setRows] = useState([]);
  const [date, setdate] = useState("This Day");


  function getStartOfWeek(date) {
    const start = new Date(date);
    const day = start.getDay();
    const diff = start.getDate() - day + (day === 0 ? -6 : 1); // Adjust if Sunday is the first day of the week
    start.setDate(diff);
    start.setHours(0, 0, 0, 0); // Normalize to start of the day

   
    return start;
  }
  
  function getEndOfWeek(date) {
    const end = new Date(date);
    const day = end.getDay();
    const diff = end.getDate() + (6 - day); // Adjust for end of week
    end.setDate(diff);
    end.setHours(23, 59, 59, 999); // Normalize to end of the day
    return end;
  }
  
  const areDatesInSameWeek = (dateToCheck) => {
    const today = new Date();
    const startOfWeek = getStartOfWeek(today);
    const endOfWeek = getEndOfWeek(today);

  
  
    // Normalize dateToCheck to start of the day for comparison
    const dateToCheckNormalized = new Date(dateToCheck);
    dateToCheckNormalized.setHours(0, 0, 0, 0);
  
    return dateToCheckNormalized >= startOfWeek && dateToCheckNormalized <= endOfWeek;
  };

  

  

  
  
  useEffect(() => {
    const dates = new Date();
    const date1 = new Date(dates.toLocaleString());
    const days = row.filter(
      ({ eventDate }) =>
        new Date(new Date(eventDate).toLocaleDateString()).getTime() ===
        new Date(dates.toLocaleDateString()).getTime()
    );
    const Months = row.filter(
      ({ eventDate }) => new Date(eventDate).getMonth() === dates.getMonth()
    );
    setRows(days);
    const weeks = row.filter(({ eventDate }) =>
      areDatesInSameWeek(
        // date1,
        new Date(new Date(eventDate).toLocaleDateString())
      )
    );
    // replace with your first date
    WeeksInfo[0].times = days.length;
    WeeksInfo[1].times = weeks.length;
    WeeksInfo[2].times = Months.length;
    setWeeksInfo([...WeeksInfo]);
  }, [row]);

  useEffect(() => {
    const dates = new Date();
    switch (date) {
      case "This Day":
        const days = row.filter(
          ({ eventDate }) =>
            new Date(new Date(eventDate).toLocaleDateString()).getTime() ===
            new Date(dates.toLocaleDateString()).getTime()
        );
        setRows(days);
        break;
      case "This Month":
        const Months = row.filter(
          ({ eventDate }) => new Date(eventDate).getMonth() === dates.getMonth()
        );
        setRows(Months);
        break;
      case "This Week":
        const date1 = new Date(dates.toLocaleString()); // replace with your first date

        const weeks = row.filter(({ eventDate }) =>
          areDatesInSameWeek(
            // date1,
            new Date(new Date(eventDate).toLocaleDateString())
          )
        );
     

        setRows(weeks);
        break;
        
      default:
    }
  }, [date]);
  return (
    <>
      <div className="bg-white p-6 my-4">
        <div className="flex justify-between items-center w-full ">
          <div>
            <h2 className="mt-3 text-black">Events</h2>
          </div>
          <div>
            <SelectComponent date={date} setdate={setdate} />
          </div>
        </div>

        <div className="overflow-x-auto mt-4 mb-6 ">
          <table className="min-w-full bg-white  shadow-lg rounded-lg  ">
            <thead className=" text-black  text-lg border-b border-gray-200">
              <tr>
                <th className="w-1/6 py-3 px-4">ID</th>
                <th className="w-1/6 py-3 px-4">Event Name</th>
                <th className="w-1/6 py-3 px-4">Franchise</th>
                <th className="w-1/6 py-3 px-4">Created At</th>
                <th className="w-1/6 py-3 px-4">Start At</th>
                <th className="w-1/6 py-3 px-4">End Date</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, indx) => (
                <tr
                  key={`${row.EventName}-${indx}`}
                  className="border-b  text-blue-800  odd:bg-white  even:bg-gray-100  "
                >
                  <td className="py-3 px-4">{row.Id}</td>
                  <td className="py-3 px-4">{row.eventName}</td>
                  <td className="py-3 px-4">{row.Franchise}</td>
                  <td className="py-3 px-4">{row.CreatedAt}</td>
                  <td className="py-3 px-4">{row.eventDate}</td>
                  <td className="py-3 px-4">{row.endDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

{
  /* <Toolbar className="text-end  d-flex justify-content-end w-100 ">
        <SelectComponent date={date} setdate={setdate} />
      </Toolbar> */
}

{
  /* <TableContainer
      component={Paper}
      className="BasicTableParent basicTableHeight"
    >
      
      <Table aria-label="simple table">
   
        <TableHead>
          <TableRow  style={{
                backgroundColor:'lightGray',

              }}>
            <TableCell >ID</TableCell>
            <TableCell >Event Name</TableCell>
            <TableCell >Franchise</TableCell>
            <TableCell >Created At</TableCell>
            <TableCell >End Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, indx) => (
            <TableRow
              key={`${row.EventName}-${indx}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{row.Id}</TableCell>
              <TableCell>{row.eventName}</TableCell>

              <TableCell>{row.Franchise}</TableCell>
              <TableCell>{row.CreatedAt}</TableCell>
              <TableCell>{row.endDate}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> */
}
