import { Field } from "formik";

export const FranchiseType = () => {
  const options = [
    "Retina",
    "Medical",
    "Immunology",
    "Neuroscience",
    "GTx",
    "In Market Brands",
    "Cardiovascular",
    "Value & Access",
    "Oncology",
  ];

  return (
    <>
      <b className=" w-100 block text-black text-sm font-bold mb-2">
        FranchiseType
      </b>
      <div className=" w-100  ">
        <Field
          as="select"
          className=" w-100 roleBox rounded"
          id={"franchiseType"}
          name={"franchiseType"}
        >
          <option value="selected"  hidden>
            Select Franchise
          </option>
          {options.map((option) =>
            typeof option == "object" ? (
              <optgroup label={option.label}>
                {option.options.map((item, index) => (
                  <option key={index} value={`${option.label}-${item}`}>
                    {item}
                  </option>
                ))}
              </optgroup>
            ) : (
              <option key={option} value={option}>
                {option}
              </option>
            )
          )}
        </Field>
      </div>
    </>
  );
};
