import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { FireBaseContext } from "../../Context/FireBase";
import { useContext, useEffect, useState } from "react";

const Tov = ({ setSelectedOptions, selectedOptions, type }) => {
  const { newEvent, setNewEvent } = useContext(FireBaseContext);

  const checkTxtValue = (e, index) => {
    const value = e.target.value.replace(/\D/g, ''); // Removes non-numeric characters
    const updatedOptions = [...selectedOptions];
    updatedOptions[index].value = value;
    setSelectedOptions(updatedOptions); // Update the state with the new value
  };

  const sendData = () => {
    setNewEvent({
      ...newEvent,
      ["TransferOfValue"]: selectedOptions,
    });
  };

  const options = [
    { types: "Registration Fees", value: 0 },
    { types: "Accommodation", value: 0 },
    { types: "CME Hours", value: 0 },
    { types: "Flights", value: 0 },
    { types: "Immunology", value: 0 },
    { types: "Meals", value: 0 },
    { types: "Medical Utilities", value: 0 },
    { types: "Transportation", value: 0 },
    { types: "Visa", value: 0 },
  ];

  return (
    <>
      <div className="">
        <label
          className="block text-black text-sm font-bold mb-2"
          htmlFor="select2"
        >
          Transfer Of Values
        </label>
        <Autocomplete

          multiple
          className="focus:outline-none"
          id="tags-outlined"
          options={options}
          value={newEvent[type]}
          getOptionLabel={(option) => option.types}
          filterSelectedOptions
          sx={{ border: 0, '& .MuiOutlinedInput-root': { border: 0 } }}
          onChange={(e, value) => {
            setNewEvent({
              ...newEvent,
              [type]: value,
              // ...newEvent,
              // ["TransferOfValue"]: value,
            });

            setSelectedOptions([...value], value);
          }}
          isOptionEqualToValue={(option, value) => option.types === value.types}
          renderInput={(params) => (
            <TextField {...params} className="border rounded" />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { onDelete, ...tagProps } = getTagProps({ index }); // Extract only necessary props
              return (
                <div
                  key={index}
                  {...tagProps}
                  className="p-2 bg-gray-400 text-white rounded mx-1"
                >
                  {`${option.types} - ${option.value}`}

                  <button onClick={onDelete}>
                    <i className="fa-solid fa-xmark text-black ml-4"></i>
                  </button>
                </div>
              );
            })
          }
        />
      </div>

      <div>
        <ul className="grid grid-cols-4 gap-3 mt-4 px-0">
          {selectedOptions.map((savedObject, index) => (
            <li key={index} className="">
              <label
                className="block text-black text-sm font-bold mb-2 w-fit"
                htmlFor="select2"
              >
                Tov : {savedObject.types}
              </label>
              <TextField
                focused
                className="border rounded w-100"
                onChange={(e) => {
                  const newValue = e.target.value;
                  // Only allow positive numbers
                  if (/^\d*\.?\d*$/.test(newValue)) {
                    checkTxtValue(e, index);
                  }
                }}
                value={savedObject.value}
              />
            </li>
          ))}
        </ul>

      </div>
    </>
  );
};

export default Tov;

// const [inputValues, setInputValues] = useState(
//   selectedOptions.map((option) => option.value || "")
// );
//     const newValues = [...inputValues];
//    newValues[i] = e.target.value;
//    setInputValues(newValues)

// value={inputValues[index]}
