import { useContext, useEffect, useState } from "react";
import "./ChosenCardStyle.css";
import { getDoc, doc } from "firebase/firestore";
import { FireBaseContext } from "../../Context/FireBase";
import { useParams } from "react-router-dom";
import data from "../../Json/currencies.json";


export const ChosenCard = () => {
  const { dbID } = useParams();
  const { EventRefrence } = useContext(FireBaseContext);
  const [ResultData, setResultData] = useState(null);

  const [currency, setCurrency] = useState({});

  useEffect(() => {
    if (ResultData) {
      const currencyObj = data.data.find((item) => item.name === ResultData.EventCurrency);
      setCurrency(currencyObj);
    }
  }, [ResultData]);


  useEffect(() => {
    const itemRef = doc(EventRefrence, dbID);
    (async () => {
      const datas = await getDoc(itemRef);
      const Result = await datas.data();
      setResultData(Result);
    })();

  }, [dbID]);
  

  return (
    // Dashboard Events Counts Card ----------------------------------
    <div className="w-100">
      {ResultData ? (
        <div className=" choosenCard bg-white p-4 rounded-2 rounded w-100 shadow">
          {/* <h2 className="EventDetailTitle ">Event Details</h2> */}
          <div className="w-full px-2 ">
         
              <h4 className="wrappingItems text-white p-2  w-fit">
                ID : {ResultData.Id}
              </h4>
      

            <div className="flex gap-x-6 ">
              <div className="w-full md:w-1/2  mb-2">
                <div className="">
                  <p className="my-2 text-gray-600 font-thin">
                    <b>Event Name:</b>
                  </p>
                  <p className="my-2 text-black font-semibold text-2xl">
                    {ResultData.eventName}
                  </p>
                </div>
                <div className="flex flex-wrap">
                  <div className="w-full md:w-1/2">
                    <div className="">
                      <p className="my-2 text-gray-600 font-thin">
                        <b>BeSure:</b>
                      </p>
                      <p className="my-2 text-black text-xl">
                        {ResultData.BeSure}
                      </p>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2">
                    <div className="">
                      <p className="my-2 text-gray-600 font-thin">
                        <b>Franchise:</b>
                      </p>
                      <p className="my-2 text-black text-xl ">
                        {ResultData.Franchise}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="w-full md:w-1/2">
                    <div className="">
                      <p className="my-2 text-gray-600 font-thin">
                        <b>Start Date:</b>
                      </p>
                      <p className="my-2 text-black text-xl">
                        {" "}
                        {ResultData.eventDate}
                      </p>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2">
                    <div className="">
                      <p className="my-2 text-gray-600 font-thin">
                        <b>End Date:</b>
                      </p>
                      <p className="my-2 text-black text-xl">
                        {" "}
                        {ResultData.endDate}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="w-full md:w-1/2">
                    <div className="">
                      <p className="my-2 text-gray-600 font-thin">
                        <b>Start Time:</b>
                      </p>
                      <p className="my-2 text-black text-xl">
                        {" "}
                        {ResultData.DateFromHours}
                      </p>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2">
                    <div className="">
                      <p className="my-2 text-gray-600 font-thin">
                        <b>End Time:</b>
                      </p>
                      <p className="my-2 text-black text-xl">
                        {" "}
                        {ResultData.DateEndHours}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="">
                    <p className="my-2  text-gray-600 font-thin">
                      <b>City:</b>
                    </p>
                    <div className="flex flex-wrap gap-2">
                      {ResultData.city.map((item, index) => (
                        <div
                          className="bg-orangeColor rounded text-white p-2 w-fit text-sm"
                          key={index}
                        >
                          {item}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full  md:w-1/2">
                <div className="">
                  <p className="my-2 text-gray-600 font-thin">
                    <b>Cost per Delegate:</b>
                  </p>
                  <p className="my-2 text-black text-xl">
                    {ResultData.CostperDelegate} {currency.symbol}
                  </p>
                </div>
                <div className="">
                  <p className="my-2 text-gray-600 font-thin">
                    <b>Transfer of value:</b>
                  </p>

                  <div className="flex flex-wrap gap-2">
                    {ResultData.TransferOfValue.map((item, index) => (
                      <div
                        className="bg-gray-400 rounded flex items-center  w-fit  my-2"
                        key={index}
                      >
                        {/* <p className="my-2 "> */}
                        <span className="text-white text-sm p-2">
                          {item.types}{" "}
                        </span>
                        <span className="text-white bg-orangeColor rounded-tr rounded-br flex items-center justify-center h-100 p-2 text-sm">
                          {item.value}
                        </span>
                        {/* </p> */}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-col flex-wrap gap-2">
                  <div className="">
                    <p className="my-2 text-gray-600 font-thin">
                      <b>Font Color:</b>
                    </p>
                    <div
                      className="border"
                      style={{
                        backgroundColor: `${ResultData.fontColor}`,
                        width: "50px",
                        height: "20px",
                        borderRadius: "5px",
                      }}
                    ></div>
                  </div>

                  <div className="">
                    <p className="my-2 text-gray-600 font-thin">
                      <b>Background Color:</b>
                    </p>
                    <div
                      className="border"
                      style={{
                        backgroundColor: `${ResultData.bgColor}`,
                        width: "50px",
                        height: "20px",
                        borderRadius: "5px",
                      }}
                    ></div>
                  </div>

                  <div className="">
                    <p className="my-2 text-gray-600 font-thin">
                      <b>Button Color:</b>
                    </p>
                    <div
                      className="border"
                      style={{
                        backgroundColor: `${ResultData.btnColor}`,
                        width: "50px",
                        height: "20px",
                        borderRadius: "5px",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="  my-3 gap-2  d-flex flex-md-wrap flex-column flex-md-row ">
            <div className="d-flex flex-column gap-2 justify-content-between ">
              <div className={`d-flex align-items-center gap-2 `}>
                <p className="m-0">
                  <b>ID:</b>
                </p>
                <p className="m-0 px-2 ">{ResultData.Id}</p>
              </div>
              <div className={`d-flex align-items-center gap-2 `}>
                <p className="m-0">
                  <b>Event Name:</b>
                </p>
                <p className="m-0 px-2 ">{ResultData.eventName}</p>
              </div>
              <div className={`d-flex align-items-center gap-2 `}>
                <p className="m-0">
                  <b>Franchise:</b>
                </p>
                <p className="m-0 px-2 ">{ResultData.Franchise}</p>
              </div>
              <div className={`d-flex align-items-center gap-2 `}>
                <p className="m-0">
                  <b>BeSure:</b>
                </p>
                <p className="m-0 px-2 ">{ResultData.BeSure}</p>
              </div>
              <div className={`d-flex align-items-center gap-2 `}>
                <p className="m-0">
                  <b>PO:</b>
                </p>
                <p className="m-0 px-2 ">{ResultData.PO}</p>
              </div>
              <div className={`d-flex align-items-center gap-2 `}>
                <p className="m-0">
                  <b>Cost per Delegate:</b>
                </p>
                <p className="m-0 px-2 ">{ResultData.CostperDelegate}</p>
              </div>
              <div className={`d-flex align-items-center gap-2 `}>
                <p className="m-0">
                  <b>Created At:</b>
                </p>
                <p className="m-0 px-2 ">{ResultData.CreatedAt}</p>
              </div>
            </div>
            <div className="d-flex flex-column gap-2 justify-content-between ">
              <div className={`d-flex align-items-center gap-2 `}>
                <p className="m-0">
                  <b>Start Date:</b>
                </p>
                <p className="m-0 px-2 ">{ResultData.eventDate}</p>
              </div>
              <div className={`d-flex align-items-center gap-2 `}>
                <p className="m-0">
                  <b>End Date:</b>
                </p>
                <p className="m-0 px-2 ">{ResultData.endDate}</p>
              </div>
              <div className={`d-flex align-items-center gap-2 `}>
                <p className="m-0">
                  <b>City:</b>
                </p>
                {ResultData.city.map((item,ind) => (
                  <div key={ind} className="wrappingItems p-1 ">{item}</div>
                ))}
              </div>
              <div className={`d-flex align-items-center gap-2 `}>
                <p className="m-0">
                  <b>Transfer of Value:</b>
                </p>
                {ResultData.TransferOfValue.map((item,ind) => (
                  <div key={ind} className="wrappingItems p-1 ">
                    <p className="m-0">
                      <span className="text-white">{item.types} :</span>
                      <span className="text-white"> {item.value}</span>
                    </p>
                  </div>
                ))}
              </div>
              <div className={`d-flex align-items-center gap-2 `}>
                <p className="m-0">
                  <b>Background Color:</b>
                </p>
                <div
                  className="showColor border border-2"
                  style={{ backgroundColor: ResultData.bgColor }}
                ></div>
              </div>
              <div className={`d-flex align-items-center gap-2 `}>
                <p className="m-0">
                  <b>Button Color:</b>
                </p>
                <div
                  className="showColor border border-2"
                  style={{ backgroundColor: ResultData.btnColor }}
                ></div>
              </div>
              <div className={`d-flex align-items-center gap-2 `}>
                <p className="m-0">
                  <b>Font Color:</b>
                </p>
                <div
                  className="showColor border border-2"
                  style={{ backgroundColor: ResultData.fontColor }}
                ></div>
              </div>
            </div>
          </div> */}
        </div>
      ) : (
        <div className="w-100 d-flex justify-content-center">
          <div className="dot-spinner">
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
            <div className="dot-spinner__dot"></div>
          </div>
        </div>
      )}
    </div>
  );
};
