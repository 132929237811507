import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FireBaseContext } from "../../Context/FireBase";
import { getYear, getMonth } from "date-fns"; // Importing getYear and getMonth from date-fns

const DatePickerInput = ({ condition, SetError, formErrors }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [value, setValue] = useState("");
  const { setNewEvent, newEvent } = useContext(FireBaseContext);

  const [startDate, setStartDate] = useState(new Date());

  const range = (start, end, step = 1) => {
    let output = [];
    for (let i = start; i < end; i += step) {
      output.push(i);
    }
    return output;
  };

  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const numericDate = new Date(date).getTime();
    const formattedDate = new Date(numericDate).toLocaleString('en-GB', {
      day: '2-digit',
      month: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    });

    if (condition) {
      setNewEvent({ ...newEvent, eventDate: formattedDate.split(",")[0] });
      setValue(formattedDate.split(",")[0]);
      if (date) {
        SetError({ ...formErrors, eventDate: "" });
      } else {
        SetError({ ...formErrors, eventDate: "Required" });
      }
    } else {
      setNewEvent({ ...newEvent, endDate: formattedDate.split(",")[0] });
      setValue(formattedDate.split(",")[0]);

      if (date) {
        SetError({ ...formErrors, EndDate: "" });
      } else {
        SetError({ ...formErrors, EndDate: "Required" });
      }
    }
  };
  useEffect(() => {
    if (condition) {
      setValue(newEvent.eventDate);
    } else {
      setValue(newEvent.endDate);
    }
  }, [newEvent.endDate, newEvent.eventDate]);
  return (
    <div className="test relative">
      <DatePicker
        className="w-100 border"
        value={condition ? newEvent.eventDate : newEvent.endDate}
        renderCustomHeader={({ date, changeYear, changeMonth }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        )}
        selected={startDate}
        onChange={(date) => handleDateChange(date)}
      />
      <div className="absolute top-3 right-3">
        <i className="fa-regular fa-calendar"></i>
      </div>
    </div>
  );
};

export default DatePickerInput;

{
  /* <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="mm/dd/yyyy"
        value={condition ? newEvent.eventDate : newEvent.endDate}
        placeholderText={"mm/dd/yyyy"}
        name={condition ? "eventDate" : "endDate"}
       
      /> */
}

{
  /* <input
        selected={selectedDate}
        onChange={handleDateChange}
        value={condition ? newEvent.eventDate : newEvent.endDate}
        name={condition ? "eventDate" : "endDate"}
        id="from-date"
        type="date"
        className=" appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
      /> */
}
