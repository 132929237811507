import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useContext, useEffect, useState } from "react";
import { FireBaseContext } from "../../../Context/FireBase";
export const FranchisedropDown = ({ SetError, formErrors }) => {
  const { newEvent, setNewEvent, currentUserRole } =
    useContext(FireBaseContext);
  const [filteredFranchise, setFilteredFranchise] = useState([]);

  const handleChange = (event) => {
    setNewEvent({ ...newEvent, Franchise: event.target.value });
    if (event.target.value) {
      SetError({ ...formErrors, Franchise: "" });
    } else {
      SetError({ ...formErrors, Franchise: "Required" });
    }
  };

  const data = [
    { types: "Immunology", label: "Immunology" },
    { types: "Neuroscience", label: "Neuroscience" },
    { types: "GTx", label: "GTx" },
    { types: "In Market Brands", label: "In Market Brands" },
    { types: "Oncology", label: "Oncology" },
    { types: "Cardiovascular", label: "Cardiovascular" },
    { types: "Medical", label: "Medical" },
    { types: "Retina", label: "Retina" },
    { types: "Value & Access", label: "Value & Access" },
  ];

  useEffect(() => {
    if (currentUserRole) {
      if (currentUserRole.manager || currentUserRole.user) {
        const filtered = data.filter(
          (franchise) =>
            franchise.types === currentUserRole.franchiseType.trim()
        );

        setFilteredFranchise(filtered);
      } else {
        setFilteredFranchise(data);
      }
    }
  }, [currentUserRole]);

  return (
    <>
      <label
        className="block text-black text-sm font-bold mb-2"
        htmlFor="select2"
      >
        Franchise
      </label>

      <select
        // labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        name="Franchise"
        value={newEvent.Franchise}
        onChange={handleChange}
        label="Franchise"
        className="appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
      >
        <option value="selected" hidden>
          Select
        </option>
        {filteredFranchise.map((item, index) => (
          <option value={item.types} key={index}>
            {item.label}
          </option>
        ))}
      </select>
    </>
  );
};

// const handleChange = (event) => {
//   setNewEvent({ ...newEvent, Franchise: event.target.value });
//   if (event.target.value) {
//     SetError({ ...formErrors, Franchise: "" });
//   } else {
//     SetError({ ...formErrors, Franchise: "Required" });
//   }
// };

// const data = [
//   { types: "Immunology", label: "Immunology" },
//   { types: "Neuroscience", label: "Neuroscience" },
//   { types: "GTx", label: "GTx " },
//   { types: "In Market Brands", label: "In Market Brands" },
//   { types: "Oncology", label: "Oncology" },
//   { types: "Cardiovascular", label: "Cardiovascular" },
//   { types: "Medical", label: "Medical" },
//   { types: "Value&Access", label: "Value & Access" },
// ];
//     // Filter franchise based on role
//     const filteredData = currentUserRole.manager
//     ? data.filter(item => item.types === currentUserRole.franchiseType)
//     : data;

{
  /* <InputLabel id="demo-simple-select-standard-label">
          <b className="p-3"> Franchise</b>
        </InputLabel> */
}
{
  /* <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          name="Franchise"
          value={newEvent.Franchise}
          onChange={handleChange}
          label="Franchise"
          className="appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
        >
        
          {data.map((item, index) => (
            <option value={item.types} key={index}>
              {item.label}
            </option>
          ))}
        </Select> */
}
