import { Field, useFormikContext } from "formik";
import { useState, useEffect } from "react";

export const RoleDropDown = ({ setRoleType }) => {
  const options = ["admin", "manager", "user"];

  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    setRoleType(values.Role);
  }, [values.Role]);
  return (
    <>
      <b className="w-100 block text-black text-sm font-bold mb-2">
        Choose Role
      </b>
      <div className="w-100  ">
        <Field
          as="select"
          className="w-100 rounded  roleBox"
          id={"Role"}
          name={"Role"}
          onChange={(e) => {
            setRoleType(e.target.value);
            setFieldValue("Role", e.target.value);
          }}
        >
          <option value="selected" hidden >
            Select Role
          </option>

          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Field>
      </div>
    </>
  );
};
